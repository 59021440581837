import React from "react";
// import { Col, Row, Card, ListGroup } from "react-bootstrap";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

const SuperbaseNGServer = () => (
  <Layout>
    <Seo
      title="Superbase NG Server"
      description="Buy Superbase NG Server - Buy Licenses for Superbase NG Multi-User Database Engine"
    />
    <div className="container">
      <main>
        <div className="px-4 pt-5 text-center">
          <h1 className="display-3">Superbase NG Server</h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              The Superbase NG Multi-User Database Engine. A powerful,
              high-speed database engine that can run on both Windows and Linux.
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center py-5">
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Basic</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  £10<small className="text-muted fw-light">/year</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>5 users included</li>
                  <li>Email support</li>
                  <li>Help center access</li>
                </ul>
                <a
                  href="https://buy.stripe.com/test_eVa7sL4rG35h69a9AB"
                  className="w-100 btn btn-lg btn-primary"
                >
                  Get Started Today
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm">
              <div className="card-header py-3">
                <h4 className="my-0 fw-normal">Professional</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  £15<small className="text-muted fw-light">/year</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>10 users included</li>
                  <li>Priority email support</li>
                  <li>Help center access</li>
                </ul>
                <a
                  href="https://buy.stripe.com/test_eVa7sL4rG35h69a9AB"
                  className="w-100 btn btn-lg btn-primary"
                >
                  Get Started Today
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card mb-4 rounded-3 shadow-sm border-primary">
              <div className="card-header py-3 text-white bg-primary border-primary">
                <h4 className="my-0 fw-normal">Enterprise</h4>
              </div>
              <div className="card-body">
                From
                <h1 className="card-title pricing-card-title">
                  £25<small className="text-muted fw-light">/mo</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Web Compatible</li>
                  <li>2 hr. Direct Support</li>
                </ul>
                <a
                  className="w-100 btn btn-lg btn-primary"
                  href="https://www.superbase.com/contact"
                >
                  Contact us
                </a>
              </div>
            </div>
          </div>
        </div>

        <h2 className="display-6 text-center mb-4">Compare plans</h2>

        <div className="table-responsive mb-3">
          <table className="table text-center">
            <thead>
              <tr>
                <th width="34%"> </th>
                <th width="22%">Basic</th>
                <th width="22%">Professional</th>
                <th width="22%">Enterprise</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Ideal for Small Businesses
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Ideal for multiple concurrent users
                </th>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Designed for Website Backend
                </th>
                <td></td>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Unlimited Databases Supported
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Daily Backup
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Basic Password
                </th>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Easy Remote Deployment Tool
                </th>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Unlimited members
                </th>
                <td></td>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Extra security
                </th>
                <td></td>
                <td></td>
                <td>
                  <svg className="bi" width="24" height="24">
                    <use href="#check"></use>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <!-- 
        <div className="container py-5" id="">
          <h2 className="pb-2 border-bottom">Gallery</h2>
          TODO: Add Some Gallery images here
        </div>
        --> */}
        <svg xmlns="http://www.w3.org/2000/svg" display="none">
          <symbol id="check" viewBox="0 0 16 16">
            <title>Check</title>
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"></path>
          </symbol>
        </svg>
      </main>
    </div>
  </Layout>
);

export default SuperbaseNGServer;
